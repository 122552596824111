import { ref } from "vue";
import { defineStore } from "pinia";

export const useDrawerStore = defineStore("drawer", () => {
  const drawerLeftOpen = ref(false);
  const drawerRightOpen = ref(false);
  const drawerTab = ref("about");

  const toggleDrawerLeft = () => {
    drawerLeftOpen.value = !drawerLeftOpen.value;
  };

  const toggleDrawerRight = () => {
    drawerRightOpen.value = !drawerRightOpen.value;
  };

  return {
    drawerLeftOpen,
    drawerRightOpen,
    drawerTab,
    toggleDrawerLeft,
    toggleDrawerRight,
  };
});
