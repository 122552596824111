<template>
  <q-layout view="lhr LpR lfr">
    <q-header v-if="$q.screen.lt.md" bordered class="bg-dark text-white">
      <q-toolbar>
        <q-btn flat label="about" no-caps square @click="toggleDrawerLeft()" />

        <q-toolbar-title class="text-center"> Chris Quezada </q-toolbar-title>

        <q-btn flat label="cv" no-caps square @click="toggleDrawerRight()" />
      </q-toolbar>
    </q-header>

    <drawer-left />
    <drawer-right />

    <q-page-container>
      <router-view />
    </q-page-container>

    <q-footer class="bg-dark text-white">
      <q-toolbar>
        <q-toolbar-title>
          <div class="text-caption text-center">{{ t("copyright") }}</div>
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import { useDrawerStore } from "src/stores/drawer";
import DrawerLeft from "src/components/drawer/DrawerLeft.vue";
import DrawerRight from "src/components/drawer/DrawerRight.vue";

const { t } = i18n.global;
const drawerStore = useDrawerStore();
const toggleDrawerLeft = drawerStore.toggleDrawerLeft;
const toggleDrawerRight = drawerStore.toggleDrawerRight;
</script>
