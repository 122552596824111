<template>
  <q-drawer show-if-above v-model="drawerLeftOpen" side="left" bordered>
    <q-scroll-area class="fit">
      <q-list padding>
        <q-item-label header>{{ t("name") }}</q-item-label>
      </q-list>
      <div class="q-px-md">
        <text-component :text="app.about.body" />
      </div>
      <q-list padding>
        <q-item-label header>{{ t("contact.title") }}</q-item-label>
      </q-list>
      <div class="q-px-md">
        <contact-form />
      </div>
    </q-scroll-area>
  </q-drawer>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useAppStore } from "src/stores/app";
import { useDrawerStore } from "src/stores/drawer";
import ContactForm from "src/components/contact/ContactForm.vue";
import TextComponent from "src/components/text/TextComponent.vue";

defineOptions({ name: "DrawerLeft" });

const { t } = i18n.global;
const appStore = useAppStore();
const { app } = storeToRefs(appStore);
const drawerStore = useDrawerStore();
const { drawerLeftOpen } = storeToRefs(drawerStore);
</script>
