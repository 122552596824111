<template>
  <div>
    <q-form @submit="onSubmit" class="q-gutter-md">
      <q-input v-model="formData.name" dense label="Name" filled required />
      <q-input
        v-model="formData.email"
        dense
        filled
        label="Email"
        required
        square
        type="email"
      />
      <q-input
        v-model="formData.message"
        dense
        filled
        label="Message"
        required
        square
        type="textarea"
      />
      <q-input v-model="honeypot" style="display: none" type="text" />
      <q-btn color="dark" label="Send" no-caps square type="submit" />
    </q-form>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineOptions({ name: "ContactForm" });

const formData = ref({ name: "", email: "", message: "" });
const honeypot = ref("");

const onSubmit = () => {
  if (!honeypot.value) {
    if (typeof window !== "undefined") {
      const mailtoLink = `mailto:chris@quezada.work?subject=Contact%20Form%20Submission&body=Name:${formData.value.name}%0AEmail:${formData.value.email}%0AMessage:${formData.value.message}`;
      window.open(mailtoLink, "_blank");
    }
  }
};
</script>
